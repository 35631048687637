import {useState, useEffect} from 'react';



export default function useGeolocation() {
    const [position, setPosition] = useState(null);

    useEffect(() => {
        const options = {
            enableHighAccuracy: true,
            maximumAge: 0,
            timeout: 3000
        };
    
        function success(position) {
            setPosition(position);
        }
    
        function error(error) {
            if (error.code === 1) setPosition('deniedGeolocation');
            else if (error.code === 3) setPosition('geolocationTimeout');
            else setPosition('error');
            console.warn(`ERROR(${error.code}): ${error.message}`);
        }
    
        if (navigator.geolocation) navigator.geolocation.getCurrentPosition(success, error, options);
        else setPosition('geolocationNotSupported');
    }, []);

    return position;
}