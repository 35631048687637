import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga4';


export default function useGoogleAnalytics4(measurementId) {
    const [initialized, setInitialized] = useState(false);

    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize(measurementId);
        setInitialized(true);
    }, []);

    useEffect(() => {
		if (initialized) gaView(location.pathname);
	}, [initialized, location.pathname]);

    function gaView(pageName) {
        if (initialized) ReactGA.send({ hitType: "pageview", page: pageName });
    }

    function gaEvent(category, action, label, value) {
        if (initialized){ 
            ReactGA.event({
            category: category,
            action: action,
            label: label,
            value: value 
        });}
    }

    return {gaView, gaEvent};
}
