import {useState, useEffect} from 'react';



export default function useAgeGate(enabled, waitUntilTrue) {
    const [age, setAge] = useState();

    useEffect(() => {
        if (waitUntilTrue === undefined) return setAge('atLeast21');
        if (waitUntilTrue) setAge(enabled ? localStorage.getItem('age') : 'atLeast21');
    }, [enabled, waitUntilTrue]);

    function handleAtLeast21() {
        setAge('atLeast21');
        localStorage.setItem('age', 'atLeast21');
    }

    function handleNot21() {
        setAge('not21');
    }

    return {age, handleAtLeast21, handleNot21};
}