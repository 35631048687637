import React, {useState, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import states from '../../assets/states.json';

import ajaxRequest from '../../assets/functions/ajaxRequest.js';

import useGeolocation from '../../hooks/useGeolocation.jsx';
import useAgeGate from '../../hooks/useAgeGate.jsx';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';
import useGoogleAnalytics4 from '../../hooks/useGoogleAnalytics4';



const Context = React.createContext();

export function Provider({children}) {
	const [authenticityToken, setAuthenticityToken] = useState(document.querySelector('head meta[name="csrf-token"]').content);
	const [offers, setOffers] = useState([]);
	const [retailers, setRetailers] = useState([]);
	const [brands, setBrands] = useState([]);
	const [loadingOffers, setLoadingOffers] = useState(false);
	const [initialOffersLoaded, setInitialOffersLoaded] = useState(false);
	const [offersFailed, setOffersFailed] = useState(false);
	const [faqs, setFaqs] = useState([]);

	const [selectedState, setSelectedState] = useState('');
	const [initialPathname] = useState(allowedInitialPathname());

	const history = useHistory();
	const location = useLocation();

	const {age, handleAtLeast21, handleNot21} = useAgeGate(false);
	const geoPosition = useGeolocation();

	const {gaEvent} = useGoogleAnalytics4('G-9D7S8BF9WM');

	useEffect(() => {
		if (!age || age === 'atLeast21') history.push(initialPathname);
		else history.push('/age');
	}, []);

	useEffect(() => {
		if (geoPosition && age === 'atLeast21') {
			if (geoPosition.coords) {
				const latitude = geoPosition.coords.latitude;
				const longitude = geoPosition.coords.longitude;

				ajaxRequest( 'GET', `/reverse_geocode?latitude=${latitude}&longitude=${longitude}`, {
					headers: [{'X-CSRF-Token': authenticityToken}],
					json: true
				} ).then( response => {
					const regionState = states.find(regionState => regionState.name.toLowerCase() === response.body.state.toLowerCase());
					setSelectedState(regionState);
					history.push(initialPathname);
				} ).catch( response => console.log(response) );
			} else {
        if (localStorage.getItem('selectedState') === null) return history.push('/location');

				setSelectedState(JSON.parse(localStorage.getItem('selectedState')));
				history.push(initialPathname);
			}
		}
	}, [age, geoPosition]);
	
	useEffect(() => {
		if (selectedState) {
			const authenticityToken = document.querySelector('head meta[name="csrf-token"]').content;
			setAuthenticityToken(authenticityToken);
	
			setOffers([]);
			setLoadingOffers(true);
			localStorage.setItem('selectedState', JSON.stringify(selectedState));

			ajaxRequest( 'GET', `/get_offers?state_name=${selectedState.name.toLowerCase()}`, {
				headers: [{'X-CSRF-Token': authenticityToken}],
				json: true
			} ).then( response => {
				setOffers(response.body);
				setLoadingOffers(false);
				setInitialOffersLoaded(true);
			} ).catch( response => {
				setLoadingOffers(false);
				setInitialOffersLoaded(true);
        setOffersFailed(true);
      } );
		}
	}, [selectedState]);

	useEffect(() => {	
		ajaxRequest( 'GET', `/get_faqs`, {
			headers: [{'X-CSRF-Token': authenticityToken}],
			json: true
		} ).then( response => {
			setFaqs(response.body);
		} ).catch( response => console.log(response) );
	}, []);

	useEffect(() => {
		if (offers.length) {
			setRetailers(Object.values(offers.reduce( (retailers, offer) => {
				offer.retailers.forEach( retailer => {
					if (retailer.name.toLowerCase() !== 'brand site retailer') retailers[retailer.name] = retailer;
				} );

				return retailers;
			}, {} )));

      setBrands(Object.values(offers.reduce( (brands, offer) => {
        if (offer.brand) brands[offer.brand.name] = {...offer.brand};
        return brands;
      }, {} )));
		}
	}, [offers]);

	function allowedInitialPathname() {
		const initialPathname = useLocation().pathname;

		const allowInitialPathname = ['/offer'].filter(allowedPartialInitialPathname => {
			return initialPathname.includes(allowedPartialInitialPathname);
		}).length > 0;

		if (allowInitialPathname) return initialPathname;
		else return '/';
	}

	const value = {
		authenticityToken,
		offers, loadingOffers, initialOffersLoaded,
		retailers,
		brands,
		faqs,
		selectedState, setSelectedState,
		initialPathname,
		history, location,
		age, handleAtLeast21, handleNot21,
		geoPosition,
		gaEvent
	}

	return <Context.Provider value={value}>
		{children}
	</Context.Provider>;
}

export default Context;