import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga';



export default function useGoogleAnalytics(measurementId) {
    const [initialized, setInitialized] = useState(false);

    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize(measurementId);
        setInitialized(true);
    }, []);

    useEffect(() => {
		if (initialized) gaView(location.pathname);
	}, [initialized, location.pathname]);

    function gaView(pageName) {
        if (initialized) ReactGA.pageview(pageName);
    }

    function gaEvent(category, action, label, value) {
        if (initialized) ReactGA.event({category, action, label, value});
    }

    return {gaView, gaEvent};
}
